<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">培训档案</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="searchData.projectCode"
                placeholder="请输入班级编码"
                size="small"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="searchData.projectName"
                placeholder="请输入班级名称"
                size="small"
                clearable
              />
            </div>
            <div title="开班单位" class="searchboxItem ci-full">
              <span class="itemLabel">开班单位:</span>
              <el-select
                size="small"
                v-model="searchData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width: 100%"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                clearable
                filterable
                v-model="searchData.areaId"
                :options="areatreeList"
                :props="propsarea"
                size="small"
              ></el-cascader>
            </div>
            <div title="班级状态" class="searchboxItem ci-full">
              <span class="itemLabel">班级状态:</span>
              <el-select
                size="small"
                v-model="searchData.projectStudyTimeState"
                placeholder="请选择班级状态"
                clearable
              >
                <el-option
                  v-for="item in classStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </div>
          </div>
          <div class="searchbox">
            <div title="开始时间" class="searchboxItem ci-full">
              <span class="itemLabel">开始时间:</span>
              <el-date-picker
                class="listInput"
                clearable
                size="small"
                v-model="searchData.startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="结束时间" class="searchboxItem ci-full">
              <span class="itemLabel">结束时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="searchData.endTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                minWidth="180"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                minWidth="180"
              />
              <el-table-column
                label="开班单位"
                align="left"
                show-overflow-tooltip
                prop="compName"
                minWidth="180"
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaNamePath"
                minWidth="150"
              />
              <el-table-column
                label="开始时间"
                align="left"
                show-overflow-tooltip
                prop="startDate"
                minWidth="90"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.startDate | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                align="left"
                show-overflow-tooltip
                prop="endDate"
                minWidth="90"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.endDate | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="学员总数"
                align="right"
                show-overflow-tooltip
                prop="userCount"
              ></el-table-column>
              <el-table-column
                label="结业人数"
                align="right"
                show-overflow-tooltip
                prop="agreeUserCount"
              />
              <el-table-column
                label="结业人数(100%)"
                align="right"
                minWidth="110px"
                show-overflow-tooltip
                prop="allAgreeUserCount"
              />
              <el-table-column
                label="结业人数(80%)"
                align="right"
                minWidth="110px"
                show-overflow-tooltip
                prop="eightyPercentaAgreeUserCount"
              />
              <el-table-column
                label="培训状态"
                align="left"
                show-overflow-tooltip
                prop="projectStudyTimeState"
              >
                <template slot-scope="scope">
                  <span>{{
                    $setDictionary(
                      "PROJECTSTATE",
                      scope.row.projectStudyTimeState
                    )
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="负责人"
                align="left"
                show-overflow-tooltip
                prop="projectUser"
              />
              <el-table-column
                label="负责人电话"
                align="left"
                show-overflow-tooltip
                prop="projectUserPhone"
                minWidth="100"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                minWidth="180"
              />

              <el-table-column
                label="操作"
                align="center"
                width="140px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" size="mini" @click="open(scope.row)"
                    >培训档案</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click="showDocsDialog(scope.row)"
                    >批量下载</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="培训档案选择"
      :visible.sync="docsDialogVisible"
      width="50%"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <div>
        <div class="ovy-a">
          <div class="downloadStyle">
            <el-form
              ref="docsFormnew"
              :model="docsFormnew"
              class="docsDialog"
              v-show="danganList.length"
            >
              <el-form-item v-for="(val, key) in danganList" :key="key">
                <el-checkbox v-model="docsFormnew[val.dictCode]">{{
                  val.dictValue
                }}</el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <el-checkbox v-model="agreeState" style="margin: 0 3%">
            <span style="color: red !important">
              根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，
              不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。我已阅读并同意上述内容。
            </span>
          </el-checkbox>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import tree from "@/components/treePopup";
export default {
  name: "trainingManagement/TrainingRecords",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      danganList: [],
      docsFormnew: {},
      searchData: {
        projectName: "", //班级名称
        projectCode: "", //班级编码
        compId: "", //开班单位
        areaId: "", //行政区域
        projectStudyTimeState: "", //班级状态
        startTime: "", //开始时间段
        endTime: "", // 结束时间段
      },

      classStatusList: [], //
      areatreeList: [], //行政区域list
      companyList: [], //开班单位列表
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 下载相关
      agreeState: false, //弹框是否同意
      selectedItem: {},
      docsDialogVisible: false,
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
    };
  },
  created() {
    this.getareatree();
    this.getLearningstateList();
    this.getCompanyList();
  },
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    // this.init();
    this.getTableHeight();
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },

  methods: {
    beforeunloadHandler(e) {
      e = e || window.event;
      if (e) {
        console.log(e);
        e.returnValue = "关闭提示";
      }
      this.getData(-1);
    },
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getLearningstateList() {
      const classStatusList = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      list.push({
        value: "",
        label: "全部",
      });
      for (const key in classStatusList) {
        if (key == "" || key == "50" || key == "60") {
          list.push({
            value: key,
            label: classStatusList[key],
          });
        }
      }
      this.classStatusList = list;
    },
    // 资源提供者
    getCompanyList(query) {
      if (query && query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    //跳转
    open(row) {
      sessionStorage.setItem("projectSourceFace", row.projectSource); //人脸审核准对石家庄班级，不允许补学
      //认证统计
      this.$router.push({
        name: "trainingManagement/TrainingRecordsSee",
        query: {
          projectId: row.projectId,
          projectName: row.projectName,
        },
      });
    },
    //  取消下载
    closeDocsDialog() {
      this.docsDialogVisible = false;
      this.agreeState = false;
      this.$refs["docsFormnew"].resetFields();
      for (let key in this.docsFormnew) {
        this.docsFormnew[key] = false;
      }
    },
    // 下载培训档案
    showDocsDialog(raw) {
      this.docsDialogVisible = true;
      this.selectedItem.id = raw.projectId;
      this.getList(raw.projectId);
      this.selectedItem.name = raw.projectName;
      this.trainTypeId = raw.trainTypeId;
      this.projectSource = raw.projectSource;
      this.areaId = raw.areaId;
      this.selectedItem.code = raw.projectCode;
    },
    getList(id) {
      this.$post("/run/project/archives", { projectId: id }).then((res) => {
        this.danganList = res.data;
      });
    },
    // 下载档案
    confirmDocsDialog() {
      console.log(this.docsFormnew);
      // return;
      if (!this.agreeState) {
        this.$message({
          type: "warning",
          message: "请先阅读提示内容并同意",
        });
        return;
      }
      let params = {
        fileDTOS: [],
      };
      for (let i in this.docsFormnew) {
        if (this.docsFormnew[i]) {
          params.fileDTOS.push({
            fileType: i,
            fileName:
              this.selectedItem.name +
              "-" +
              this.danganList.find((el) => el.dictCode == i).dictValue,
            className: className,
            param: {
              projectId: this.selectedItem.id,
            },
          });
        }
      }
      if (!params.fileDTOS.length) {
        this.$message.error("请至少选择一项进行打印");
        return;
      }
      // if (!params.fileDTOSAll.length) {
      //   this.$message.error("请至少选择一项进行打印");
      //   return;
      // }

      this.$post("/sys/download/asyncDownloadFile", params).then((re) => {
        if (re.status == "0") {
          let list = re.data;
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
          this.closeDocsDialog();
        } else {
          this.$message.error(re.message);
        }
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageSize: this.pageSize,
      };
      if (this.searchData.projectCode) {
        params.projectCode = this.searchData.projectCode;
      }
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      if (this.searchData.projectStudyTimeState) {
        params.projectStudyTimeState = this.searchData.projectStudyTimeState;
      }
      if (this.searchData.startTime) {
        params.startDate = this.searchData.startTime[0];
        params.endDate = this.searchData.startTime[1];
      }
      if (this.searchData.endTime) {
        params.endStartDate = this.searchData.endTime[0];
        params.endEndDate = this.searchData.endTime[1];
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/run/project/projectCertList",
        params,
        pageNum,
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data || [];
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
</style>
